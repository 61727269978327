@import '../config/variables';

@mixin mobile-bp {
  @media (max-width: #{$screen-phone-min + 5}) {
    @content;
  }
}

@mixin mobile-bp-max {
  @media (max-width: #{$screen-tablet-min}-1) {
    @content;
  }
}

@mixin tablet-bp {
  @media (min-width: #{$screen-tablet-min}) {
    @content;
  }
}

@mixin laptop-bp {
  @media (min-width: #{$screen-laptop-min}) {
    @content;
  }
}

@mixin desktop-bp {
  @media (min-width: #{$screen-desktop-min}) {
    @content;
  }
}

@mixin desktop-lg-bp {
  @media (min-width: #{$screen-desktop-lg-min}) {
    @content;
  }
}

@mixin grid($rowHeight: 1fr) {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-auto-rows: $rowHeight;
}

@mixin max-wrapper-width() {
  display: block;
  max-width: 1440px;
  margin: 0 auto;
}

@mixin box-sizing-reset() {
  box-sizing: border-box;

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
}
