@import '../../../../styles/mixins/element-mixins';
@import '../../../../styles/mixins/layout-mixins';
@import '../../../../styles/mixins/font-mixins';
@import '../../../../styles/config/variables';

.button {
  border: 0.5px solid;
  padding: 16px 24px;
  @include link-small($font-manrope-normal);
  font-weight: 500;
  letter-spacing: 0.2em;
  width: fit-content;
  white-space: nowrap;
  display: flex;
  align-items: center;
}
.button:hover {
  cursor: pointer;
}

.arrow {
  display: flex;
  align-items: center;
  margin-left: 3px;
}
