@import '../../../../../styles/mixins/element-mixins';
@import '../../../../../styles/mixins/layout-mixins';
@import '../../../../../styles/mixins/font-mixins';
@import '../../../../../styles/config/variables';

.mainSection {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
}

.imageWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;

  .image {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.upperPart {
  display: flex;
  height: 90%;
  margin-bottom: auto;
  color: $tp-white;
  width: 100%;
}

.content {
  flex-direction: column;
  display: flex;
  height: 100%;
  width: 100%;
  z-index: 2;
  grid-column: 1;
  grid-row: 1;
  margin: 32px;

  .heading {
    margin-block: 8px;
    @include heading3;
  }
  .subheading {
    @include paragraph-XS;
    letter-spacing: 2.2px;
    font-weight: 500;
  }
  .description {
    margin-block: 8px;
    @include paragraph-S;
  }

  .buttonWrapper {
    margin: 16px 0;
    .button {
      @include subHeading4;
      font-family: $font-serif-normal;
      font-size: 16px;
      padding: 16px 24px;
      width: fit-content;
      white-space: nowrap;
    }
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  opacity: 0.1;
  pointer-events: all;
}
