@import '../../../../styles/mixins/element-mixins';
@import '../../../../styles/mixins/layout-mixins';

.heroBannerSection {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: none;

  @include desktop-bp() {
    flex-direction: row;
    display: grid;
    grid-template-columns: 10% 10% 10% 10% 10% 10% 10% 10% 10% 10%;
    grid-template-rows: 50% 50%;
  }
}

.shortBanner {
  background-color: red;
  @include desktop-bp() {
    height: 100% !important;
    min-height: 100% !important;
  }
}
.bannerSectionWrapper {
  height: 85vh;
  min-height: 640px;
  @include desktop-bp() {
    height: 100%;
  }

  &.full_width {
    &:nth-child(1) {
      width: 100%;
      @include desktop-bp() {
        grid-area: 1 / 1 / 3 / 11;
      }
    }
  }

  &.sixty_forty {
    &:nth-child(1) {
      width: 100%;
      @include desktop-bp() {
        grid-area: 1 / 1 / 3 / 7;
        height: 100%;
      }
    }
    &:nth-child(2) {
      width: 100%;
      height: 48vh;
      min-height: 360px;
      @include desktop-bp() {
        grid-area: 1 / 7 / 3 / 11;
        height: 100%;
      }
    }
  }

  &.fifty_fifty {
    &:nth-child(1) {
      height: 68vh;
      min-height: 520px;
      width: 100%;
      @include desktop-bp() {
        grid-area: 1 / 1 / 3 / 6;
      }
    }
    &:nth-child(2) {
      height: 68vh;
      min-height: 520px;
      width: 100%;
      @include desktop-bp() {
        grid-area: 1 / 6 / 3 / 11;
      }
    }
  }

  &.three_way_split {
    &:nth-child(1) {
      width: 100%;
      @include desktop-bp() {
        grid-area: 1 / 1 / 3 / 7;
      }
    }
    &:nth-child(2) {
      width: 100%;

      min-height: 360px;
      height: 48vh;
      @include desktop-bp() {
        grid-area: 1 / 7 / 2 / 11;
      }

      h1 {
        font-size: 48px;
        line-height: 48px;
      }
    }

    &:nth-child(3) {
      width: 100%;

      min-height: 360px;
      height: 48vh;
      @include desktop-bp() {
        grid-area: 2 / 7 / 3 / 11;
      }

      h1 {
        font-size: 48px;
        line-height: 48px;
      }
    }
  }
}
